import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import Container from '../../components/Container'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = 'Nasza oferta'
    const serwices = data.allMarkdownRemark.edges

    const allSerwises = serwices.map(({ node }) => (
      <div key={node.fields.slug} className="column is-6">
        <div className="box ">
          <Link to={'/oferta' + node.fields.slug}>
            <div className="content">
              <h2 className="title is-size-5">{node.frontmatter.title}</h2>

              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          </Link>
        </div>
      </div>
    ))

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        slides={data.heroSlides}
        images={data.heroImages}
      >
        <SEO
          title={siteTitle}
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <section className="section is-midle">
          <Container>
            <div className="columns is-multiline">{allSerwises}</div>
          </Container>
        </section>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 80)
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
